import { MetaMaskProvider, type MetaMaskSDKOptions } from '@metamask/sdk-react'
import { CustomMetaMaskModal } from './connect-metamask-modal/CustomMetaMaskModal'
import config from '../../config'
import blockchainsConfig from '@baanx/blockchain-config'
import { getInfuraApiKey } from '@baanx/common/network/blockchain/config'

import { createRoot } from 'react-dom/client'
import { useLocation } from 'react-router-dom'
import { AppMode } from '@baanx/common/types'
import FoxTheme from '../../themes/FoxTheme'

const CustomMetaMaskProvider = (props) => {
    const location = useLocation()
    const isFox = location.pathname.includes(AppMode.FOX)

    // Deeplink not working on IOS @ CHROME
    const useDeeplink = true
    const blockchainConfig = blockchainsConfig[config.environment]
    const readonlyRPCMap = Object.keys(blockchainConfig).reduce(
        (acc, cur) => ({
            ...acc,
            [blockchainConfig[cur].chainId?.toLowerCase()]:
                blockchainConfig[cur].jsonRpcUrl,
        }),
        {}
    )

    const infuraAPIKey = getInfuraApiKey(config)
    const sdkOptions: MetaMaskSDKOptions = {
        useDeeplink,
        readonlyRPCMap,
        infuraAPIKey,
        logging: {
            developerMode: true,
            sdk: true,
        },
        forceInjectProvider: true,
        openDeeplink(link: string) {
            const isInIframe = window !== window.parent;
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
            
            // please ignore this bypasses to the window.ethereum object 
            // @ts-expect-error @metamask/sdk-react 
            const hasEthereum = typeof window.ethereum !== 'undefined';
            
            // please ignore this bypasses to the window.ethereum object
            // @ts-expect-error @metamask/sdk-react 
            const isMetaMaskInApp = hasEthereum && window.ethereum?.isMetaMask === true;
            
            // Check if we're in actual MetaMask app - not just with an injected provider
            const isActualMetaMaskApp = isMetaMaskInApp && /MetaMask/i.test(navigator.userAgent);
        
            // First check if we're in the actual MetaMask app
            if (isActualMetaMaskApp) {
              window.location.href = link;
              console.log(`deeplink attempted in actual MetaMask app: ${link}`);
            } 
            // Then handle iOS Safari in iframe case
            else if (isIOS && isInIframe) {
              console.log(`deeplink attempted in iframe+iOS Safari: ${link}`);
              window.postMessage({ 
                type: 'OPEN_DEEPLINK', 
                link,
              }, '*');
            } 
            // Default fallback for all other cases
            else {
              window.open(link, '_blank');
              console.log(`deeplink attempted in normal mode: ${link}`);
            }
          },
        dappMetadata: {
            name: 'App',
            url: window.location.protocol + '//' + window.location.host,
        },
        modals: isFox
            ? {
                  install: ({ link, connectWithExtension }: any) => {
                      let modalContainer: HTMLElement | null
                      let root
                      const myClose = () => {
                          if (modalContainer) {
                              root.unmount(modalContainer)
                              modalContainer.remove()
                          }
                      }
                      return {
                          mount: () => {
                              if (modalContainer) return

                              modalContainer = document.createElement('div')
                              modalContainer.id = 'meta-mask-modal-container'
                              document.body.appendChild(modalContainer)
                              root = createRoot(modalContainer)

                              root.render(
                                  <FoxTheme>
                                      <CustomMetaMaskModal
                                          link={link}
                                          connectWithExtension={() => {
                                              myClose()
                                              connectWithExtension?.()
                                          }}
                                          onClose={myClose}
                                      />
                                  </FoxTheme>
                              )
                          },
                          unmount: () => {
                              if (modalContainer) {
                                  root.unmount(modalContainer)
                                  modalContainer.remove()
                              }
                          },
                      }
                  },
              }
            : undefined,
    }

    return (
        <MetaMaskProvider debug={true} sdkOptions={sdkOptions}>
            {props.children}
        </MetaMaskProvider>
    )
}

export default CustomMetaMaskProvider

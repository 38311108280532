import { type ContractTransaction, ethers, type BrowserProvider, type JsonRpcProvider } from 'ethers'
import TokenABI from '@baanx/abis/ERC20.json'
import {
    type Transaction,
    type BlockchainConfig,
    type BlockchainService,
    type SupportedToken,
    type SupportedBlockchain,
} from '../../../types'
import { getParsedAmount } from '../../../utils'

export const blockchainProvider = async (
    providerParam: any,
    blockchainConfig: BlockchainConfig,
): Promise<BlockchainService> => {
    const cfg = blockchainConfig
    let provider: BrowserProvider | JsonRpcProvider
    // instanceOf does not work in the production build. Checking manually.
    if ( typeof providerParam.send === 'function' && typeof providerParam.getNetwork === 'function') {
        provider = providerParam
    } else provider = new ethers.BrowserProvider(providerParam as ethers.Eip1193Provider)

    const jsonProvider = new ethers.JsonRpcProvider(cfg.jsonRpcUrl)
    const contractAddress = cfg.contractAddress

    const gasLimit: Partial<Record<SupportedBlockchain, bigint>> = {
        etherlink: 1000000n,
        linea: 90000n,
    }
    const APPROVE_GAS_LIMIT = gasLimit[blockchainConfig.blockchain] ?? 64000n
    const erc20Contracts =
        Object.keys(cfg.token).reduce(
            (acc, cur) => ({
                ...acc,
                [cur]: new ethers.Contract(
                    cfg.token[cur].address,
                    TokenABI.abi,
                    jsonProvider
                ),
            }),
            {}
        )
    const tokenDecimals = {}
    const tokens = Object.keys(cfg.token) as SupportedToken[]
    for (const token of tokens) {
        const decimals = await erc20Contracts[token].decimals()
        tokenDecimals[token] = decimals
    }

    // TODO: I needed to refactor this method because on the FE we were missing a lot of confirmations. I tried to use the wssProvider but the same happened. 
    const waitForTransaction = async (hash: string): Promise<void> => {
        const maxAttempts = 30; 
        const interval = 2000; 
        let attempts = 0;
        while (attempts < maxAttempts) {
            await new Promise(resolve => setTimeout(resolve, interval));
            console.log("waitForTransaction", hash, attempts)
            const receipt = await jsonProvider.getTransactionReceipt(hash);
            
            if (receipt) {
                const confirmations = await receipt.confirmations()
                if (confirmations >= 1) {
                    return; // Transaction is confirmed
                }
            }
            
            attempts++;
        }
        
        throw new Error(`Transaction ${hash} failed to confirm after ${maxAttempts} attempts`);
    }

    const getBalance = async (accountId: string): Promise<string> => {
        if (!provider) throw Error('Not connected')
        const balance = await provider.getBalance(accountId)
        return ethers.formatUnits(balance)
    }

    const getAllowance = async (
        accountId: string,
        tokenType: SupportedToken
    ): Promise<string> => {
        const allowance = await erc20Contracts[tokenType]?.allowance(
            accountId,
            contractAddress
        )
        const isInfinite = ethers.MaxUint256.toString() === allowance.toString()
        const decimals = tokenDecimals[tokenType]
        const decimalsToUse = isInfinite ? 0 : decimals
        return ethers.formatUnits(allowance, decimalsToUse)
    }

    const approve = async (
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> => {
        const signer = await provider.getSigner()
        const decimals = tokenDecimals[tokenType]
        const parsedAmount = getParsedAmount(amount, decimals)
        const contractWithSign = getContractWithSign(
            TokenABI,
            tokenType,
            signer
        )
        
        const tx = await contractWithSign.approve(
            contractAddress,
            parsedAmount,
            {
                gasLimit: APPROVE_GAS_LIMIT,
            }
        )

        return tx
    }

    const buildApproveTransaction = async (
        amount: string,
        tokenType: SupportedToken
    ): Promise<ContractTransaction> => {
        const decimals = tokenDecimals[tokenType]
        const parsedAmount = getParsedAmount(amount, decimals)
        const contract = new ethers.Contract(cfg.token[tokenType].address, TokenABI.abi, provider)

        return await contract.approve.populateTransaction(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            contractAddress,
            parsedAmount,
            {
                gasLimit: APPROVE_GAS_LIMIT,
            }
        )

    }

    const balanceOf = async (
        accountId: string,
        tokenType: SupportedToken
    ): Promise<string> => {
        const balance = await erc20Contracts[tokenType].balanceOf(accountId)
        const decimals = tokenDecimals[tokenType]
        return ethers.formatUnits(balance, decimals)
    }

    const decimals = (tokenType: SupportedToken): number => {
        return tokenDecimals[tokenType]
    }

    const getContractWithSign = (
        contract: typeof TokenABI,
        tokenType: SupportedToken,
        sign: ethers.JsonRpcSigner
    ): ethers.Contract => {
        return new ethers.Contract(
            cfg.token[tokenType].address,
            contract.abi,
            sign
        )
    }

    const paymentEth = async (amount: string): Promise<Transaction> => {
        const tx = {
            to: cfg.paymentAddress,
            value: ethers.parseEther(amount),
            gasLimit: 22000,
        }
        const signer = await provider.getSigner()
        const { hash, wait } = await signer.sendTransaction(tx)
        return { hash, wait }
    }

    const paymentErc20 = async (
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> => {
        const signer = await provider.getSigner()
        const contract = getContractWithSign(TokenABI, tokenType, signer)
        const decimals = tokenDecimals[tokenType]
        return await contract.transfer(
            cfg.paymentAddress,
            ethers.parseUnits(amount, decimals),
            { gasLimit: 100000 }
        )
    }

    const payment = async (
        amount: string,
        unit: SupportedToken
    ): Promise<Transaction> => {
        if (unit === 'eth') return await paymentEth(amount)
        return await paymentErc20(amount, unit)
    }

    const signMessage = async (message: string): Promise<string> => {
        const signer = await provider.getSigner()
        return await signer.signMessage(message)
    }
    const signTypedData = async (
        domain,
        types,
        message
    ): Promise<ethers.SignatureLike> => {
        const signer = await provider.getSigner()
        const signature = await signer.signTypedData(domain, types, message)
        const { r, s, v } = ethers.Signature.from(signature)
        return { r, s, v }
    }

    return {
        waitForTransaction,
        getBalance,
        getAllowance,
        approve,
        balanceOf,
        decimals,
        payment,
        signMessage,
        signTypedData,
        buildApproveTransaction
    }
}


    import config from "./src/config";
    const isAndroid = /Android/.test(navigator.userAgent);
    const isMmApp = /MetaMask/i.test(navigator.userAgent);
    if (isAndroid && isMmApp) {
      window.ethereum = window.parent.ethereum
    }
    //workaround to make phantom wallet works within an iframe. The client must also set document.domain with the same super domain. 
    try {
      document.domain = config.domain
    } catch (error) {
      console.warn('Could not change document.domain: ', error.message);
    }
  